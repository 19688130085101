import React, { useMemo } from 'react';
import { Box } from '@tanium/react-box';
import styled from '@tanium/react-emotion-9';
import { css } from '@tanium/style-system';
import CannotEnroll from './CannotEnroll';
import EmailSignIn from './EmailSignIn';
import EnrollIcon from './EnrollIcon';
import Download from './Download';

const Container = styled(Box)(
  css({
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '5%',
  })
);

interface Props {
  token: string | undefined;
  canEnroll: boolean | undefined;
}

const EnrollContent: React.VFC<Props> = ({ token, canEnroll }) => {
  const canDownload = useMemo(() => {
    return canEnroll && !!token;
  }, [canEnroll, token]);

  const mustSignIn = useMemo(() => {
    return !token;
  }, [token]);

  const cannotEnroll = useMemo(() => {
    return !canEnroll && !!token;
  }, [canEnroll, token]);

  return (
    <Container mx={2}>
      <EnrollIcon />
      {mustSignIn && <EmailSignIn />}
      {canDownload && <Download token={token!} />}
      {cannotEnroll && <CannotEnroll />}
    </Container>
  );
};

export default EnrollContent;
