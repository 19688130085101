import React from 'react';
import { Box } from '@tanium/react-box';
import { TaniumSpinner } from '@tanium/react-tanium-spinner';
import styled from '@tanium/react-emotion-9';
import { css } from '@tanium/style-system';
import AppHeader from '../AppHeader/AppHeader';
import TopContent from '../TopContent/TopContent';
import EnrollContent from '../EnrollContent/EnrollContent';
import AppHeaderStyle from '../AppHeader/AppHeaderStyle';
import TopContentStyle from '../TopContent/TopContentStyle';
import { AuthSession } from '../../utils/Auth';
import WaveBG from '../../static/sine_waves_v2.svg';

const headerHeight =
  AppHeaderStyle.borderHeight +
  AppHeaderStyle.containerHeight +
  TopContentStyle.containerHeight +
  TopContentStyle.dividerHeight;

const Body = styled(Box)(
  css({
    marginTop: `${headerHeight}px`,
    height: `calc(100vh - ${headerHeight}px)`,
    overflowX: 'hidden',
    overflowY: 'auto',
  })
);

const EnrollContentContainer = styled('div')(
  css({
    backgroundImage: `url(${WaveBG})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100%',
    minHeight: '350px',
  })
);

interface Props {
  loading: boolean;
  session: AuthSession | null;
}

const Shell: React.VFC<Props> = ({ session, loading }) => {
  return (
    <>
      <AppHeader username={session?.username} />
      <TopContent />
      <Body>
        <EnrollContentContainer>
          {loading ? (
            <Box style={{ paddingTop: '10%' }}>
              <TaniumSpinner />
            </Box>
          ) : (
            <EnrollContent token={session?.accessToken} canEnroll={session?.canEnroll} />
          )}
        </EnrollContentContainer>
      </Body>
    </>
  );
};

export default Shell;
